<template>
  <div id="qrbox" :class="[
      {offlineMode: offlineMode},
      _position,
      _size,
      _hidden,
      {darkmode: darkmode}]">
    <div class="top">
      <div class="scan-me">
        <h2>{{ $t('system.teanimas') }}</h2>

        <div v-if="offlineMode">
          <span>
            <strong>1-</strong> {{ $t('system.firstPlaneMode') }}
          </span>

          <div class="my-4">
            <span><strong>2-</strong> {{ $t('system.thenConnectWifi') }} "<strong>{{ this.$store.state.setup.others?.offlineSSID }}</strong>"</span>
          </div>
          
        </div>

        <span><strong v-if="offlineMode">3-</strong> {{ $t('system.scanMe') }}</span>
      </div>

      <div class="qr">
        <VueQRCodeComponent :text="fullurl" error-level="L"></VueQRCodeComponent>
      </div>
    </div>

    <div class="bottom">{{ urlDomain }}</div>

  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
export default {
  components: { VueQRCodeComponent },
  props: {
    position: {
      type: String,
      required: false, // center, left, right
      default: "",
    },
    size: {
      type: String,
      required: false, // large, small
      default: ""
    },
    darkmode: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      shorturl: "",
      fullurl: "",
      urlDomain: "",
    };
  },
  computed: {
    offlineMode(){
      return this.$store.state.setup.others?.offlineMode
    },
    coso(){
      return this.$store.getters["space/config"].experience.qrStandyBy
    },
    _hidden(){
      if(this.$store.getters["space/config"].experience.qrStandyBy.position == 'hidden') return "hidden";
      if(this.hidden) return "hidden";
      return ""
    },
    _position() {
      if (this.position == "") {
        return this.$store.getters["space/config"].experience.qrStandyBy.position || "center";
      } else {
        return this.position;
      }
    },
    _size() {
      if (this.size == "") {
        if (this.$store.state.space.players.length == 0){
          return this.$store.getters["space/config"].experience.qrStandyBy.size || "large";
        }
        return "small";
      } else {
        return this.size;
      }
    },
  },
  mounted() {
    this.fullurl = window.location.href;
    let urlfirstpart = window.location.protocol + "//";
    this.shorturl = window.location.href.replace(urlfirstpart, "");
    this.fullurl = this.fullurl.replace("/spaces", "");
    this.shorturl = this.shorturl.replace("/spaces", "");
    this.urlDomain = this.shorturl.split('/')[0]
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

$unit: 2.16px;

@mixin setSize($size) {
  width: $size;
  height: $size;

  &.left {
    left: calc(($size/2) - 1px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &.hidden {
      left: -$size;
    }

    .bottom {
      border-bottom-left-radius: 0;
    }
  }

  &.right {
    left: calc(100vw - ($size/2) + 1px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    top: 65%;

    &.hidden {
      left: calc(100vw + $size);
    }

    .bottom {
      border-bottom-right-radius: 0;
    }
  }
}

#qrbox {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  top: 50px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;  
  $border-radius: 2px;
  $transition: all 0.7s cubic-bezier(0.21, -0.22, 0.3, 1);
  transition: $transition;
  color: white;

  &.offlineMode {
    top: 10px;


    .qr {
      width: 120px !important;
    }

    .plane-square {
      color: black;
      background: rgba($white-bis,.8);
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      margin-inline: auto;
    }

    .line-after {
      position: relative;
      margin-bottom: 32px;

      &::after {
        content: '';
          width: $unit * 20;
          height: $unit;
          display: block;
          background-color: white;
          position: absolute;
          left: 50%;
          bottom: -$unit * 10;
          transform: translate(-50%);
      }
  
    }
  }

  strong {
    color: white;
  }

  .top {
    flex: 1;
    font-size: 2.5em;
    transition: $transition;

    .scan-me {
      padding: $unit * 5;

      h2 {
        font-weight: bold;
        font-size: $unit * 15.5;
        line-height: 1.1;
        margin-bottom: 1em;
        position: relative;

        &:after {
          content: '';
          width: $unit * 20;
          height: $unit;
          display: block;
          background-color: white;
          position: absolute;
          left: 50%;
          bottom: - $unit * 10;
          transform: translate(-50%);
        }
      }

      span {
        font-size: $unit * 9.2;
      }
    }
  }

  .qr {
    transition: $transition;
    margin: 0 auto;
    padding: $unit * 4;
    background: $white-bis;
    border-radius: $border-radius;
  }


  &.large {
    @include setSize(500px);

    .bottom {
      padding: 15px;
      font-size: 1.3em;
    }

  }

  &.small {
    @include setSize(216px);
  
    .top {
      font-size: 1rem;
    }

    .qr {
      width: $unit * 74;
    }

    .bottom {
      padding: $unit * 2;
      font-size: $unit * 7.2;
    }
  }

  &.darkmode {

    background-color: rgba($black-bis,0.8);
    color: $white-bis;

    .bottom {
      background-color: rgba($black-bis,0.5);
    }

  }
}
</style>
